/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-04-24",
    versionChannel: "nightly",
    buildDate: "2023-04-24T00:24:11.496Z",
    commitHash: "a2ece35c12c18746b4e3f6be08c6bb48d65a18d2",
};
